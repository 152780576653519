@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {

  @font-face {
    font-family: 'NissanLight';
    src: url('./fonts/NissanLight.eot');
    src: url('./fonts/NissanLight.eot?#iefix') format('embedded-opentype'),
      url('./fonts/NissanLight.woff') format('woff'),
      url('./fonts/NissanLight.ttf') format('truetype'),
      url('./fonts/NissanLight.svg#NissanBrandW01-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'NissanRegular';
    src: url('./fonts/NissanRegular.eot');
    src: url('./fonts/NissanRegular.eot?#iefix') format('embedded-opentype'),
      url('./fonts/NissanRegular.woff') format('woff'),
      url('./fonts/NissanRegular.ttf') format('truetype'),
      url('./fonts/NissanRegular.svg#NissanBrandW01-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'NissanBold';
    src: url('./fonts/NissanBold.eot');
    src: url('./fonts/NissanBold.eot?#iefix') format('embedded-opentype'),
      url('./fonts/NissanBold.woff') format('woff'),
      url('./fonts/NissanBold.ttf') format('truetype'),
      url('./fonts/NissanBold.svg#NissanBrandW01-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }


  html,
  body {
    @apply font-[NissanRegular] scroll-smooth;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply tracking-normal;
  }

  h2 {
    @apply text-4xl;
  }

  h3 {
    @apply text-2xl font-[NissanBold];
  }

  strong {
    @apply font-[NissanBold]
  }
}

@layer components {
  body {
    overflow-x: hidden;
    overflow-y: scroll;
    margin-right: calc(-1 * (100vw - 100%));
  }

  main {
    @apply  text-black flex items-start justify-center relative overflow-hidden;
  }

  .loading {
    @apply items-center;
  }


  .container {
    @apply mx-auto max-w-screen-xl flex flex-col bg-brand-light-gray;
  }

  .main-heading {
    @apply py-10 px-8 xl:px-0;
  }

  .main-content {
    @apply relative h-full flex flex-col lg:flex-row grow;
  }


  .sidebar {
    @apply sticky top-0 left-0 flex flex-col w-full lg:w-[250px] xl:w-[180px] shrink-0 z-50;
  }

  .mobile-menu {
    @apply bg-black lg:hidden grid grid-cols-2 gap-[1px] py-[1px] w-full;
  }

  .trigger {
    @apply bg-brand-light-gray h-16;
  }

  .widget {
    @apply bg-brand-light-gray relative mt-0 lg:mt-4 px-8 xl:px-0;
  }

  .widget h3 {
    @apply text-lg font-[NissanRegular] border-t border-t-black pt-4;
  }

  .widget.categories {
    @apply mt-0 lg:mt-8;
  }

  .widget.mobile {
    @apply bg-transparent w-full min-h-lvh fixed top-0 transition-all duration-300 ease-out;
  }

  .widget.mobile.categories {
    @apply -left-full;
  }

  .widget.mobile.vehicles {
    @apply -right-full;
  }

  .widget.mobile.active-widget {
    @apply bg-brand-light-gray;
  }

  .widget.mobile.categories.active-widget {
    @apply -left-0;
  }

  .widget.mobile.vehicles.active-widget {
    @apply -right-0;
  }

  .widget--mobile-header {
    @apply hidden h-24 mb-0 items-center justify-between;
  }

  .widget--mobile-header h4 {
    @apply font-[NissanBold] text-2xl text-brand-red;
  }

  .widget.mobile .widget--mobile-header {
    @apply flex;
  }

  .widget--close-btn {
    @apply bg-brand-red text-white rounded-full p-1;
  }

  .widget--close-btn svg {
    @apply w-4 h-4;
  }

  .widget--list {
    @apply flex flex-col gap-4 mt-6 mb-4;
  }

  .widget--list-item {
    @apply cursor-pointer flex gap-2 items-center transition-opacity;
  }

  .widget--list-item[data-status="disabled"] {
    @apply opacity-20;
  }

  .widget--list-item input,
  .widget--list-item span {
    @apply transition-all duration-300 ease-out text-base font-[NissanLight];
    ;
  }

  .widget--list-item input {
    @apply relative appearance-none w-3 h-3 aspect-square bg-white outline outline-2 outline-brand-gray/50 rounded-full;
  }

  .widget--list-item input:checked {
    @apply outline-black;
  }

  .widget--list-item input::before {
    @apply content-[""] bg-black w-3 h-3 rounded-full absolute top-0 left-0 origin-center transition-all duration-300 ease-out scale-0;
  }

  .widget--list-item input:checked::before {
    @apply scale-105;
  }


  .vehicle--grid {/*h-full*/
    @apply  grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-8 p-8 box-border;
  }

  .card {
    @apply pointer-events-none w-full h-fit lg:h-auto order-2 bg-white p-6 flex flex-col gap-6 transition-all duration-500 ease-out hover:shadow-2xl overflow-hidden origin-center opacity-20 scale-100;
  }

  .card.active-card {
    @apply pointer-events-auto order-1 flex opacity-100 scale-100;
  }

  .card--title h3 { @apply uppercase; }

  .card--title,
  .card--image {
    @apply flex items-center justify-center flex-col;
  }

  .card--content {
    @apply flex items-center justify-center;
  }

  .card--content-info {
    @apply flex flex-col items-center justify-between grow;
  }
  .card--content-notes {
    /* margin-bottom: -10px;
    margin-top: -10px; */
    @apply flex flex-col items-center justify-between grow -mt-4 -mb-4;
  }

  .price {
    @apply flex items-start text-2xl md:text-4xl font-[NissanLight];
  }

  .price span {
    @apply text-lg font-[NissanRegular];
  }

  .price button {
    @apply ml-1 text-lg flex items-center justify-center;
  }

  .card--action {
    @apply grid gap-2 font-[NissanRegular] text-base;
  }

  .btn {
    @apply flex items-center justify-center gap-2 border text-center py-2  first-of-type:bg-brand-red first-of-type:border-brand-red first-of-type:text-white bg-brand-grayclear;
  }


  .btn.primary {
    @apply bg-brand-red border-brand-red text-white;
  }

  .btn:hover svg {
    @apply translate-x-2;
  }

  .btn,
  .btn svg {
    @apply transition-all duration-500 ease-out;
  }

  .card:hover .btn:nth-child(1) {
    @apply bg-transparent border-brand-red text-brand-red;
  }

  .card:hover .btn:nth-child(2) {
    @apply bg-transparent border-black text-black;
  }


   /* .card:hover .btn:nth-child(1) {
    @apply bg-brand-red border-brand-red text-white;
  }

  .card:hover .btn:nth-child(2) {
    @apply bg-brand-light-gray border-brand-light-gray text-black;
  } */


  .modal {
    @apply bg-black/75 backdrop-blur-sm w-screen h-screen flex items-center justify-center fixed top-0 left-0 z-[99] transition-all duration-300 ease-out opacity-0 origin-center pointer-events-none;
  }

  .modal.active {
    @apply opacity-100 pointer-events-auto;
  }
  .modal--wrapper {
    @apply fixed top-0 left-0 z-[99] w-screen flex items-center justify-center;
  }
  .modal--content {
    @apply bg-white p-8 w-full max-w-screen-md max-h-screen overflow-y-scroll drop-shadow-xl flex flex-col origin-center translate-y-full opacity-0 transition-all duration-500 ease-out;
  }

 .modal--wrapper.mobile {
    @apply items-start justify-start;
  }



  .modal.active .modal--content {
    @apply translate-y-0 opacity-100;
  }

  .modal--content-tile {
    @apply flex items-center justify-between w-full border-b border-b-black pb-6;
  }

  .modal--content-tile h2 {
    @apply text-xl md:text-3xl;
  }

  .modal--content-body h5 {
    @apply font-[NissanBold] uppercase text-brand-red text-2xl mt-8 mb-4;
  }
}


/* Swiper container stylng */
.swiper {
  width: 100%;
  height: 467px;
}

#swiper .swiper { @apply w-full h-[640px] md:h-[467px]; }

.slider--content-wrapper { @apply flex items-end lg:items-center w-full h-full relative z-10 pb-14 md:pb-16 lg:pb-0 overflow-hidden; }
.slider--content-wrapper::after { @apply content-[""] block md:hidden absolute bottom-0 left-0 bg-gradient-to-t from-black w-full h-3/4; }

.slider--content { @apply w-full lg:w-2/3 h-full relative text-white z-20 flex flex-col items-start justify-end md:justify-center px-4 lg:pl-16 gap-6 md:gap-10; }

.slider--content-info { @apply flex flex-col items-start justify-end text-left w-full; }
.slider--content-info h2 { @apply text-2xl md:text-4xl font-["NissanBold"]; }
.slider--content-info h2 span { @apply text-lg md:text-4xl font-["NissanLight"]; }
.slider--content-info p { @apply text-base md:text-lg font-["NissanLight"]; }

.slider--content-action { @apply flex flex-col lg:flex-row gap-4 w-full md:w-fit; }
.slider--content-action .btn { @apply py-2 md:py-4 px-4; }
.slider--content-wrapper .btn.red { @apply bg-brand-red  text-white border-brand-red }
.slider--content-wrapper .btn { @apply  bg-brand-grayclear border-brand-gray text-black; }
.slider--content-wrapper .btn:hover { @apply first-of-type:bg-brand-red lg:first-of-type:bg-transparent first-of-type:border-brand-red lg:first-of-type:border-brand-gray bg-brand-gray border-brand-gray lg:bg-transparent text-white text-sm md:text-base; }

.slider--content-wrapper img { @apply w-full h-full absolute top-0 left-0 object-contain object-bottom md:object-cover sm:object-center md:object-right z-0;   }


#slider .swiper-button-prev,
#slider .swiper-button-next {
  @apply hidden lg:block;
  color: #ffffff !important;
}

#slider .swiper-pagination-bullet { @apply bg-white bottom-4; }
#slider .swiper-pagination-bullet-active { @apply bg-brand-red opacity-75; }



.swiper-slide {
  text-align: center;
  /* Center slide text vertically */
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 460px;
  object-fit: cover;
}
